<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Message answer</div>
    </template>
    <div class="p-mb-3">{{ header }}</div>
    <div id="iframe-container" class="iframe-container">
      <iframe id="message-frame"
              allow="fullscreen"
              seamless
              :srcdoc="srcdoc">
      </iframe>
    </div>
    <template #footer>
      <Button :label="$t('Close')" icon="pi pi-times" class="p-button-text" @click="close"/>
    </template>
  </Modal>
</template>
<script>
import formatMixins from "@/mixins/formatMixins";

export default {
  mixins: [ formatMixins ],
  components: { },
  emits: ['close', 'update-items'],
  name: 'EmailModal',
  props: {
    item: Object,
    visible: Boolean,
  },
  data() {
    return {
      header: '',
      srcdoc: null
    }
  },
  watch: {
    item(value) {
      // console.log(value)
      const textColorDiv = this.$store.state.user.schema === 'light' ? '<div style="color: #495057">' : '<div style="color: #FFFFFFDE">'

      let answer = textColorDiv
      let header = ''
      if (value.created_at) {
        // this.header += `<span><b>${this.formatDate(value.created_at)}</b></span>`
        header += `${this.formatDate(value.created_at)}`
      }
      if (value.createdBy) {
        // this.header += `<span><b>, ${value.createdBy.first_name} ${value.createdBy.last_name}:</b></span>`
        header += `, ${value.createdBy.first_name} ${value.createdBy.last_name}:`
      }
      if (value.html_text) {
        answer += value.html_text + '</div>'
      } else if (value.plain_text) {
        answer += `<div style="white-space: pre-wrap">${value.html_text}</div>`
      }

      this.header = header
      this.srcdoc = answer
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '630px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '980px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.iframe-container iframe {
  border: 0;
  padding: 0;
  //position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  height: 450px;
}
</style>