<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Message sending</div>
    </template>
    <Spinner v-show="dataIsSending"></Spinner>
    <div v-show="!dataIsSending" class="p-formgrid p-grid p-pt-3" >
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
<!--          <InputText :class="{'p-invalid' : submitted && !emailData.sender}" id="message_sender_modal" v-model.trim="emailData.sender" autocomplete="off"/>-->
<!--          <label for="message_sender_modal">From<span class="warning-color">*</span></label>-->
<!--        </div>-->
<!--        <small class="p-invalid" v-if="submitted && !emailData.sender">{{ $t('Required field') }}</small>-->
          <CustomDropdown id="message_sender_modal"
                              :class="{'p-invalid' : submitted && !selectedSender}"
                              v-model="selectedSender"
                              :options="userEmailAccounts"
                          :clearSearchData="!visible"
                              :filterFields="['username']"
                              :filter="true"
                              optionLabel="username"
                              :showClear="false">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.username }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.username }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="message_sender_modal">Sender<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedSender">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText :class="{'p-invalid' : submitted && (!emailData.to_email || !receiverEmailIsValid) }" id="message_receiver_modal" v-model="emailData.to_email" @input="validateEmail($event.target.value)" autocomplete="off"/>
          <label for="message_receiver_modal">To<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !emailData.to_email">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-else-if="submitted && emailData.to_email && !receiverEmailIsValid">{{ $t('Value is not a valid email address') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText :class="{'p-invalid' : submitted && !emailData.subject}" id="message_subject_modal" v-model.trim="emailData.subject" autocomplete="off"/>
          <label for="message_subject_modal">Subject<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !emailData.subject">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <ModifiedEditor
            @text-change="textChange"
            id="email-editor"
            v-model="messageText"
            :clearMessageText="!visible"
            editorStyle="height: 260px">
          <template #toolbar>
<!--            <span class="ql-formats">-->
<!--              <button class="ql-bold"></button>-->
<!--              <button class="ql-italic"></button>-->
<!--              <button class="ql-underline"></button>-->
<!--            </span>-->
          </template>
        </ModifiedEditor>
        <small class="p-invalid" v-if="submitted && !messageText">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5" v-show="emailData.attachments?.length">
        <div class="p-d-flex p-ai-center p-mb-3" v-if="!emailData.fromOrderFilesPage">
          <InputSwitch id="attach-files-switch" v-model="attachFiles"/>
          <label for="attach-files-switch" class="switch-label pointer">Attach files from original message?</label>
        </div>
<!--        <div v-else>Files:</div>-->
        <div v-if="attachFiles">
          <div v-for="attachment of emailData.attachments" :key="attachment.id" class="p-d-flex p-ai-center">
<!--            <div class="p-d-flex p-ai-center p-jc-start">-->
<!--              <div>{{ attachment.source_name }}</div>-->
<!--              <div><i class="ti-close"></i></div>-->
<!--            </div>-->
<!--            {{ attachment.source_name }} <i class="ti-close p-link" style="position: relative; top: 2px;"></i>-->
            <Button @click="toggleAttachmentStatus(attachment)" :icon="attachment.is_removed ? 'ti-plus' : 'ti-close'" class="p-button-text"></Button>
            <div class="p-ml-1" :class="{'removed-attachment' : attachment.is_removed}">{{ attachment.source_name }}, {{ $t('Size') }}: {{ formatDecimal(+attachment.size / 1000000) }} MB</div>
          </div>
        </div>
      </div>


<!--      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5" v-if="emailData.isOffer || emailData.isSalesInvoice">-->
<!--        <div class="p-d-flex p-ai-center">-->
<!--          <InputSwitch id="send-document-switch" v-model="attachDocument"/>-->
<!--          <label for="send-document-switch" class="switch-label pointer">Attach pdf</label>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5" v-if="emailData.isOffer">-->
<!--        <div class="p-d-flex p-ai-center">-->
<!--          <InputSwitch id="send-sms-switch" v-model="sendSms"/>-->
<!--          <label for="send-sms-switch" class="switch-label pointer">Send sms</label>-->
<!--        </div>-->
<!--      </div>-->

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-d-flex p-ai-center p-flex-wrap p-mb-5" v-if="(emailData.isOffer && emailData.containsActivePositions) || emailData.isSalesInvoice && (showAttachDocumentButton || showSendSmsButton)">
        <div v-if="showAttachDocumentButton" class="p-d-flex p-ai-center p-mr-3">
          <InputSwitch id="send-document-switch" v-model="attachDocument"/>
          <label for="send-document-switch" class="switch-label pointer">Attach pdf</label>
        </div>
        <div v-if="showSendSmsButton" class="p-d-flex p-ai-center">
          <InputSwitch id="send-sms-switch" v-model="sendSms"/>
          <label for="send-sms-switch" class="switch-label pointer">Send sms</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5" v-if="sendSms">
        <div class="p-float-label p-input-icon-right" >
<!--          <i class="ti-comment-alt"></i>-->
          <Textarea disabled id="customer_comment" v-model.trim="smsText" rows="2" :autoResize="true" autocomplete="off"/>
          <label for="customer_comment">Sms message text</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12" v-if="computedAttachedFilesTotalSize" :class="computedAttachedFilesTotalSize > settings.maxMessageSize ? 'warning-color' : 'green-color'">
        {{ $t('Size') }}: {{ formatDecimal(+computedAttachedFilesTotalSize / 1000000, true, 3) }} MB / {{ formatDecimal(settings.maxMessageSize / 1000000) }} MB
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <ModifiedFileUpload
            :clearAttachments="!visible"
            name="demo[]"
            @select="onFileSelect"
            @clear="onFilesClear"
            @remove="onFileRemove"
            chooseLabel="Add attachment"
            uploadLabel="Upload11!!"
            cancelLabel="Clear"
            :maxFileSize="5242880"
            invalidFileSizeMessage="File size should be smaller (Max: 5mb)">
          <template #empty>
            <div class="p-text-center">
              <p>Drag and drop files to here to upload.</p>
            </div>
          </template>
        </ModifiedFileUpload>
      </div>

<!--      <input type="file" id="fileInput" ref="fileInput" multiple @change="onFileChange">-->

    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button label="Send" icon="pi pi-send" class="p-button-text" @click="sendMessage" />
    </template>
  </Modal>
</template>
<script>
import httpClient from '@/services/http.services'
// import httpMixins from "@/mixins/httpMixins";
import ModifiedFileUpload from "@/components/ModifiedFileUpload";
import ModifiedEditor from "@/components/ModifiedEditor";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ formatMixins, showErrorsMixins ],
  components: { ModifiedFileUpload, ModifiedEditor },
  emits: ['close', 'update-items', 'update-item', 'update-message'],
  name: 'EmailModal',
  props: {
    item: Object,
    visible: Boolean,
    selectedEmailId: Number,
    userEmailAccounts: Array,
    offerData: Object,
    // receivedMessage: String
  },
  data() {
    return {
      demoToEmail: 'info@topsoft.ee',
      smsText: '',
      attachDocument: true,
      showAttachDocumentButton: true,
      sendSms: false,
      showSendSmsButton: true,
      attachFiles: true,
      // editor: ClassicEditor,
      // editorData: '<div>Content of the editor.</div>',
      // editorConfig: {
      //   toolbar: {
      //     // items: [ 'bold', 'italic', 'link', '|', 'undo', 'redo', 'strike', { size: [ 'small', false, 'large', 'huge' ]}],
      //   }
      // },
      dataIsSending: false,
      selectedSender: null,
      // userEmailAccounts: null,
      // uploadedFiles: [],
      emailData: {},
      signature: '',
      submitted: false,
      attachments: [],
      receiverEmailIsValid: false,
      messageText: null,
      // htmlText: null,
      // plainText: null,
      file: null,
      clearAttachments: false,
      // clearMessageText: false,
      // files: [],
      settings
    }
  },
  watch: {
    item(value) {
      let signature = ''
      if (this.userEmailAccounts) {
          const firstAccount = this.userEmailAccounts[0] ?? null

          if (this.selectedEmailId) {
            const filteredAccount = this.userEmailAccounts.filter(a => a.id === this.selectedEmailId)[0] ?? null
            if (filteredAccount) {
              this.selectedSender = filteredAccount
              signature = filteredAccount.signature ?? ''
            } else {
              this.selectedSender = firstAccount
              signature = firstAccount.signature ?? ''
            }
          } else {
            if (value.isOffer) {
              const defaultAccountForOffer =  this.userEmailAccounts.find(a => a.is_default_for_offers) ?? null
              if (defaultAccountForOffer) {
                this.selectedSender = defaultAccountForOffer
                signature = defaultAccountForOffer.signature ?? ''
              } else {
                this.selectedSender = firstAccount
                signature = firstAccount.signature ?? ''
              }
            } else if (value.isSalesInvoice) {
              const defaultAccountForInvoice =  this.userEmailAccounts.find(a => a.is_default_for_invoices) ?? null
              if (defaultAccountForInvoice) {
                this.selectedSender = defaultAccountForInvoice
                signature = defaultAccountForInvoice.signature ?? ''
              } else {
                this.selectedSender = firstAccount
                signature = firstAccount.signature ?? ''
              }
            } else {
              this.selectedSender = firstAccount
              signature = firstAccount.signature ?? ''
            }
          }
      }

      this.emailData = { ...value }

      if (settings.isDevMode) {
        this.emailData.to_email = this.demoToEmail
      }

      if (value.forward) {
        this.emailData.to_email = null
      }

      if (this.emailData.to_email) {
        this.validateEmail(this.emailData.to_email)
      }

      // if (value.reply) {
      //   this.parentMessageId = value.id
      // }

      if (value.subject) {
        if (value.reply) {
          this.emailData.subject = 'Re: ' + value.subject
        } else if (value.forward) {
          this.emailData.subject = 'Fwd: ' + value.subject
        }
      }

      if ((value.forward || value.fromOrderFilesPage) && value.attachments?.length) {
        this.attachFiles = true
        this.emailData.attachments = value.attachments
        this.emailData.attachments.forEach(a => a.is_removed = false)
      } else {
        this.attachFiles = false
        this.emailData.attachments = []
      }

      // if (value.file_path) {
      //   this.attachDocument = true
      //   this.disabledAttachDocumentButton = false
      //   this.attachments.push(value.file_path)
      //   // console.log(value.file_path)
      // } else {
      //   this.attachDocument = false
      //   this.disabledAttachDocumentButton = true
      // }
      // this.emailData.subject = 'Test message'
      // // this.emailData.htmlText = 'Hea Dmitry ,<br>' +
      // //     'Täname, et olete kasutanud CARVEX OÜ teenuseid!<br>' +
      // //     'Soovime alati pakkuda parimat teenust ja sealjuures püüdleme igapäevaselt selle poole, et tulevikus oma klientide ootusi veelgi paremini täita. Just sellepärast ongi Teie tagasiside meile väga oluline.'
      // //
      let message = '<br>'

      if (value.isOffer || value.isSalesInvoice || value.forward) {
        message = ''

      }

      if (value.sales_invoice_text) {
        message += `<div style="white-space: pre-wrap">${value.sales_invoice_text}</div>`
      }


      if (value.offer_id) {
        if (value.greeting) {
          message += `<div style="white-space: pre-wrap">${value.greeting}</div>`
        }
        if (value.offer_text) {
          message += `<div style="white-space: pre-wrap">${value.offer_text}</div>`
        }
        if (value.offer_link_text && value.offer_pin_text) {
          message += `<div style="white-space: pre-wrap">${value.offer_link_text} ${value.offer_pin_text}</div>`
        }

        const containsActivePositionBoolean = !!value.containsActivePositions
        this.attachDocument = containsActivePositionBoolean
        this.showAttachDocumentButton = containsActivePositionBoolean
        this.sendSms = containsActivePositionBoolean
        this.showSendSmsButton = containsActivePositionBoolean
        this.smsText = value.sms_text ? value.sms_text : ''
      } else {
        this.sendSms = false
        this.showSendSmsButton = false
        this.smsText = ''
      }

      message += '<br>'

      if (!value.forward) {
        message += `<div style="white-space: pre-wrap">${signature}</div></br>`
      }

      if (value.reply) {
        message += `<i>On ${value.created_at ? this.formatDate(value.created_at) : ''}, ${value.from_name} &lt;${value.from_email}&gt; wrote:</i></br></br>`
      } else if (value.forward) {
        message += '<div>---------- Forwarded Message ----------</div>' +
                   `<div>From: ${value.from_name ?? ''} &lt;${value.from_email ?? ''}&gt; ` +
                   `<div>Date: ${this.formatDate(value.created_at)}` +
                   `<div>Subject: ${value.subject || ''}` +
                   `<div>To: ${value.to_name ?? ''} &lt;${value.to_email ?? ''}&gt; </br>`
                    // '<div>Subject: </div>' +
      }

      if (value.html_text) {
        message += value.html_text
      } else if (value.plain_text) {
        message += `</br><div style="white-space: pre-wrap">${value.plain_text}</div></br></br>`
      } else {
        message += '<div></div>'
      }

      this.messageText = message
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.selectedSender = null

        // this.emailData.htmlText = ''
        // this.emailData.plainText = ''
        //
        // this.emailData.html_text = ''
        // this.emailData.plain_text = ''

        // this.clearMessageText = true

        this.attachFiles = true
        this.attachments = []

        this.attachDocument = true
        this.sendSms = true

        this.emailData.attachments = []
        // this.emailData.attachments.forEach(att => att.is_removed = false)

        this.clearAttachments = true

        // setTimeout(() => this.clearMessageText = false, 1000)

        // this.emailData = {}

        // console.log('Html: ' + this.emailData.htmlText, 'Plain text: ' + this.emailData.plainText)
      } else {
        this.clearAttachments = false
      }
    },
  },
  methods: {
    async sendSmsMessage() {
      try {
        const { status, data } = await httpClient.post('sms/send', { smsText: this.smsText, branchId: this.$store.state.branchData.id })
        if (status === 200 && data) {
          const smsApiSettings = data
          console.log(smsApiSettings)
        }
      } catch(err) {
        this.showError(err)
      }
    },
    toggleAttachmentStatus(attachment) {
      attachment.is_removed = !attachment.is_removed
    },
    textChange(data) {
      // this.emailData.htmlText = data.htmlValue ?? null
      this.emailData.plainText = data.textValue ?? null
    },
    onFileChange($event) {
    //   // console.log($event.target.files)
      this.attachments = $event.target.files || []
    //   console.log(this.attachments)
    //
    //   // this.attachments.forEach(attachment => {
    //   //   this.files.push(attachment)
    //   // })
    //   // this.files = $event.target.files[0]
    //   //
    //   // console.log(this.file)
    // },
    // messageOnInput() {
    //   console.log(this.emailData.htmlText)
    },
    onFileSelect(data) {
      this.attachments = data?.files || []
      // console.log(data.files)
    },
    onFilesClear() {
      this.attachments = []
    },
    onFileRemove(data) {
      this.attachments = data?.files || []
    },
    async sendMessage() {
      if (!this.emailData) return false
      this.submitted = true
      this.dataIsSending = true
      if (!this.selectedSender ||
          !this.emailData.to_email ||
          !this.emailData.subject ||
          !this.receiverEmailIsValid ||
          !this.messageText ||
          this.computedAttachedFilesTotalSize > settings.maxMessageSize) {
        this.dataIsSending = false
        return false
      }
      // const obj = {
      //   email: {
      //     ...this.emailData,
      //     // plain_text: this.emailData.htmlText.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' '),
      //     plain_text: this.emailData..plainText,
      //     from_email: this.selectedSender.username,
      //     from_name: this.selectedSender.sender_name,
      //     email_account_id: this.selectedSender.id
      //   },
      //   attachments: this.attachments,
      //   signature: this.signature,
      //   treatment: null
      // }
    //

      // const plainText =  this.emailData.plainText ?  this.emailData.plainText : this.messageText.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;|&lt;/g, ' ')
      const plainText = this.emailData.plainText ?? ''

      let formData = new FormData();
      formData.append('from_email', this.selectedSender?.username ?? '')
      formData.append('from_name', this.selectedSender?.sender_name ?? '')
      formData.append('email_account_id', this.selectedSender?.id ?? '')
      formData.append('subject', this.emailData.subject ?? '')
      formData.append('html_text', this.messageText ?? '')
      // formData.append('plain_text', this.emailData.htmlText.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' '))
      formData.append('plain_text', plainText)
      formData.append('to_email', this.emailData.to_email ?? '')
      formData.append('offer_id', this.emailData.offer_id ?? '')
      formData.append('sales_invoice_id', this.emailData.sales_invoice_id ?? '')
      formData.append('attachDocument', this.attachDocument ? 1 : 0)
      formData.append('sendSms', this.sendSms ? 1 : 0)
      formData.append('smsText', this.smsText.length ? this.smsText : 0)
      formData.append('userId', this.emailData.user_id ?? null)
      formData.append('branchId', this.emailData.branch_id)
      formData.append('fromOrderFilesPage', this.emailData.fromOrderFilesPage ? 1 : 0)

      let attachedFiles = null
      // if (this.emailData.forward && this.emailData.attachments?.length) {
      if (this.emailData.attachments?.length) {
        if (this.attachFiles) {
          attachedFiles = this.emailData.attachments.filter(a => !a.is_removed)
          formData.append('attachedFiles', JSON.stringify(attachedFiles));
        }
      }

      if (this.emailData?.reply && this.emailData.id) {
        formData.append('parent_id', this.emailData.id);
      }

      const attachmentsLength = this.attachments?.length
      if (attachmentsLength) {
        for (let i = 0; i < attachmentsLength; i++)
          formData.append(i, this.attachments[i])
      }

      const message = {
        email_account_id: this.selectedSender.id,
        is_reply: this.emailData?.reply,
        is_forward: this.emailData?.forward,
        html_text: this.messageText,
        // plain_text: plainText,
      }

      const item = {
        id: this.emailData.offer_id,
        sent_by: {
          first_name: this.$store.state.user.first_name,
          last_name: this.$store.state.user.last_name,
        },
        sent_at: +new Date(new Date() / 1000)
      }

      try {
        const { status, data } = await httpClient.post(`mail/send`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        // console.log(data)
        if (status === 201 && data?.success) {
          let itemId = this.emailData.offer_id ?? this.emailData.sales_invoice_id
          // if (this.emailData.offer_id) {
          //   itemId = this.emailData.offer_id
          // } else if (this.emailData.sales_invoice_id) {
          //   itemId = this.emailData.sales_invoice_id
          // }
        // if (status === 200) {
        //   this.$emit('update-items', itemId, false)
          this.$emit('update-item', itemId)

          if (!this.emailData.sent_at) {
            this.$emit('update-item', item)
          }
          this.$emit('update-message', message)
          this.$toast.add({severity: 'success', detail: 'Message sent', life: settings.toastLife});
          // this.attachments = []
          this.close()

          if (data.sms_is_not_sent) {
            this.$toast.add({severity:'error', detail: 'Sms sending error', life: this.settings.toastLife});
          }
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (!isCompany) {
      this.receiverEmailIsValid = re.test(email)
      // } else {
      //   this.companyEmailIsValid = re.test(email)
      // }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '630px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '980px'}
      }
    },
    computedAttachedFilesTotalSize() {
      let value = 0
      if (this.attachFiles && this.emailData.attachments?.length) {
        value += this.emailData.attachments.filter(a => !a.is_removed).reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
      }
      if (this.attachments?.length) {
        value += this.attachments.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
      }
      return value
    }
  },

}
</script>

<style scoped lang="scss">
//.test {
//  color: rgba(46, 199, 0, 0.18);
//}
.removed-attachment {
  text-decoration: line-through;
}
</style>