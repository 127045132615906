<template>
  <div v-if="message?.attachments?.length">
    <div class="p-d-flex p-ai-center">
      <Button @click="toggleFilesOverlay($event, message.id)"
              class="p-button-text">
        <div class="p-d-flex p-ai-center">
          <i class="ti-clip"></i>
          <div>Attachments</div>
        </div>
      </Button>
      <div class="p-ml-1 p-badge badge">{{ message?.attachments?.length }}</div>
    </div>
    <OverlayPanel :ref="'eab-' + message.id">
      <ul v-if="message.attachments?.length">
        <li v-for="(attachment, index) of message.attachments" :key="index">
          <template v-if="attachment.type === 1" >
            <a :href="settings.apiEndPoint + settings.imapAttachmentFolder + attachment.file_path" target="_blank">{{ attachment.source_name }}</a>
          </template>

          <template v-else-if="attachment.type === 2">
            <a :href="settings.apiEndPoint + settings.orderFilesFolder + attachment.file_path" target="_blank">{{ attachment.source_name }}</a>
          </template>

          <template v-else-if="attachment.type === 3">
            <a @click.prevent="getOfferPdf(attachment.file_path)" href="#">{{ attachment.source_name }}</a>
          </template>

          <template v-else-if="attachment.type === 5">
            <a @click.prevent="getSalesInvoicePdf(attachment.file_path)" href="#">{{ attachment.source_name }}</a>
          </template>

<!--          <a v-else-if="attachment.type === 3" @click.prevent="getOfferPdf(filePath)" href="#"></a>-->

        </li>
      </ul>
      <div v-else>{{ $t('No data available' )}}</div>
    </OverlayPanel>
  </div>
</template>

<script>
import settings from "@/settings";
import httpClient from "@/services/http.services";
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ httpMixins ],
  name: 'EmailAttachmentsButton',
  props: {
    message: Object
  },
  data() {
    return {
      settings
    }
  },
  methods: {
    async getSalesInvoicePdf(filePath) {
      if (!filePath) return false
      try {
        const { status, data } = await httpClient(`sales-invoice/get-pdf?filePath=${filePath}`, {responseType: 'arraybuffer'})
        // const { status, data } = await httpClient(`offer/get-pdf?filePath=${item?.file_path}`, {responseType: 'blob'})
        if (status === 200 && data) {
          let blob = new Blob([data], { type: 'application/pdf' }),
              url = URL.createObjectURL(blob)

          window.open(url, '_blank')
          URL.revokeObjectURL(url);
        }
      } catch(err) {
        this.showError(err)
      }
      // this.isLoading = false
    },
    async getOfferPdf(filePath) {
      if (!filePath) return false
      try {
        const { status, data } = await httpClient(`offer/get-pdf?filePath=${filePath}`, {responseType: 'arraybuffer'})
        // const { status, data } = await httpClient(`offer/get-pdf?filePath=${item?.file_path}`, {responseType: 'blob'})
        if (status === 200 && data) {
          // let blob = new Blob([data], { type: 'application/octet-stream' }),  -- DOWNLOAD FILE
          let blob = new Blob([data], { type: 'application/pdf' }),
              url = URL.createObjectURL(blob)

          window.open(url, '_blank')
          URL.revokeObjectURL(url);
        }
      } catch(err) {
        this.showError(err)
      }
      // this.isLoading = false
    },
    toggleFilesOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['eab-' + id.toString()].toggle(event);
    },
  }
}
</script>

<style scoped lang="scss">
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding: 5px 0;
    margin: 0;
  }
}

.badge {
  position: relative;
  right: 12px;
}
</style>